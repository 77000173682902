const promotions = [
  {
    path: '/promotions',
    name: 'promotions.list',
    component: () => import('@/views/promotions/PromotionsList.vue'),
  },
  {
    path: '/change-promotions',
    name: 'change-promotion.index',
    component: () => import('@/views/promotions/ChangePromotion.vue'),
  },
  {
    path: '/promotions/:uuid/details',
    name: 'promotions.details',
    component: () => import('@/views/promotions/PromotionDetails.vue'),
  },
  {
    path: '/promotions/business-inteligence',
    name: 'promotions.business-inteligence',
    component: () => import('@/views/promotions/PromotionBusinessInteligence.vue'),
  },
  {
    path: '/timeline/promotion',
    name: 'promotions.timeline',
    component: () => import('@/views/promotions/PromotionTimeLine.vue'),
  },
  {
    path: '/imports/log',
    name: 'promotions.import.log',
    component: () => import('@/views/promotions/GlobalPromotionImportList.vue'),
  },
  {
    path: '/coupons/create',
    name: 'admin.coupons',
    component: () => import('@/views/coupons/UserCouponForm.vue'),
  },
]

export default promotions
