<template>
  <div>
    <page-header name="Participantes">
      <b-button
        v-if="isAccessToRoleAndTypeCoupon()"
        variant="primary"
        class="mr-1"
        @click="$router.push('/coupons/create')"
      >
        <FeatherIcon icon="SendIcon" />
        &nbsp;<span class="text-nowrap">Enviar Cupons</span>
      </b-button>
      <b-button
        v-if="isAccessToRoleAndTypeCoupon()"
        class="mr-1"
        variant="primary"
        @click="$router.push('/participant-validate')"
      >
        <FeatherIcon icon="MenuIcon" />
        &nbsp;<span class="text-nowrap">Validação de Participantes</span>
      </b-button>

      <b-button
        v-if="hasBusinessInteligenceConfiguration"
        variant="primary"
        class="mr-1"
        @click="$router.push({'name':'promotions.business-inteligence'})"
      >
        <span class="text-nowrap">Business Inteligence</span>
      </b-button>
      <b-button
        v-if="currentPrizeDraw.promotion.settings.can_import_file && isShowButtonsFilesImport"
        variant="secondary"
        class="mr-1"
        @click="showImportDetails()"
      >
        <FeatherIcon icon="ListIcon" />
        &nbsp;<span class="text-nowrap">Histórico de Importações</span>
      </b-button>
      <b-dropdown
        v-if="currentPrizeDraw.promotion.settings.can_import_file && isShowButtonsFilesImport"
        id="dropdown-1"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        text="Participantes"
        right
        variant="primary"
      >
        <b-dropdown-item @click="importFile">
          <feather-icon
            icon="UploadCloudIcon"
            size="18"
          />&nbsp; Importar Arquivo
        </b-dropdown-item>
        <b-dropdown-item @click="updateParticipants">
          <feather-icon
            icon="RefreshCwIcon"
            size="18"
          />&nbsp; Atualizar Participantes
        </b-dropdown-item>
      </b-dropdown>
    </page-header>
    <b-row class="match-height">
      <b-col
        sm="6"
        md="4"
      >
        <chart-median-numbers-by-person :data="1"/>
      </b-col>
      <b-col
        sm="6"
        md="4"
      >
        <chart-bar-participant-per-state />
      </b-col>
      <b-col
        sm="6"
        md="4"
      >
        <chart-bar-participant-per-origin/>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col>
          <b-card-title>Participantes</b-card-title>
        </b-col>
        <b-col class="text-right">
          <b-dropdown
            id="dropdown-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="Ações"
            right
            variant="outline-secondary"
          >
            <b-dropdown-item @click="onExportSecap">
              <feather-icon
                icon="PrinterIcon"
                size="18"
              />&nbsp; Emitir Arquivo para SEAE
            </b-dropdown-item>
            <b-dropdown-item @click="onExportXls">
              <feather-icon
                icon="PrinterIcon"
                size="18"
              />&nbsp; Exportar para XLS
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <Grid
        :fields="fields"
        :items="items"
        edit-button-label="edit"
        new-button-label="new"
        :show-search="false"
        @onChangePage="getParticipants"
      >
        <template v-slot:search>
          <b-row class="p-0 pl-1">
            <b-col
              md="10"
              class="p-0 pr-1"
            >
              <b-form-input
                v-model="search.search"
                class="filter-input"
                placeholder="Pesquisar por Nome, email ou cpf"
                name="nome"
                @keyup.enter="getParticipants"
              />
            </b-col>
            <b-col
              sm="12"
              md="2"
            >
              <b-button
                variant="secondary"
                type="button"
                @click="getParticipants"
              >
                <FeatherIcon icon="SearchIcon" />
                <span
                  class="text-nowrap"
                />
              </b-button>
            </b-col>
          </b-row>
        </template>
        <template #cell(name)="data">
          <b-media vertical-align="center" style="cursor: pointer" @click="setCurrentParticipant(data.item)">
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.name)"
              />
            </template>
            <span v-if="data.item.name ">{{ data.item.name }} <br></span>

            <small v-if="data.item.email" class="text-muted">
              <feather-icon icon="AtSignIcon"/> &nbsp; {{ data.item.email }}<br></small>
            <small v-if="data.item.username" class="text-muted">
              <feather-icon icon="UserIcon"/> &nbsp;{{ data.item.username }}</small>
          </b-media>
        </template>
        <template
          #cell(created_at)="data"
          style="width: 20%"
        >
          <div class="px-4 text-center">
            {{ data.item.created_at | moment('DD/MM/YYYY') }}
          </div>
        </template>
        <template #cell(ineligibility)="data">
          <div v-if="data.item.ineligibility">
            <b-badge pill
                     variant="danger"
            >Não
            </b-badge>
            <br>
            <small class="text-muted">{{ data.item.ineligibility_reason }}</small>
          </div>
          <b-badge
            v-if="!data.item.ineligibility"
            pill
            variant="success"
          >
            Sim
          </b-badge>
        </template>
        <template
          #cell(action)="data"
          style="width: 20%"
        >
          <div class="px-4 text-center">

            <i2-icon-button
              icon="EyeIcon"
              class="mr-1"
              @click="setCurrentParticipant(data.item)"
            />
          </div>
        </template>
      </Grid>
    </b-card>
    <participant-detail-modal
      title="Detalhes do Participante"
      :show.sync="isShowParticipant"
      :participant-uuid="currentParticipant"
      @onClose="onCloseParticipantModal"
    />

    <i2-side-bar
      :is-active.sync="isUploadActivated"
      window-title="Importar Arquivo"
    >
      <prize-draw-file-import
        :is-active.sync="isUploadActivated"
        :prize-draw="currentPrizeDraw"
        :is-update-participants="isUpdateParticipants"
        @onModelSave="onSave()"
        @onCancel="onCancelEdit()"
      />
    </i2-side-bar>
    <promotion-import-log-modal :promotion-uuid="currentPromotion.uuid" :show.sync="isShowImportLog"
                                @onClose="onCloseLogImport"
    />
  </div>
</template>
<script>

import ChartBarParticipantPerState from '@/views/dashboards/participants/ChartBarParticipantPerState.vue'
import ChartBarParticipantPerOrigin from '@/views/dashboards/participants/ChartBarParticipantPerOrigin.vue'
import ChartMedianNumbersByPerson from '@/views/dashboards/participants/ChartMedianNumbersByPerson.vue'
// eslint-disable-next-line import/no-cycle
import ParticipantDetailModal from '@/views/participants/ParticipantDetailModal'
import Ripple from 'vue-ripple-directive'
import PrizeDrawFileImport from '@/views/prize-draw/PrizeDrawFileImport'
import PromotionImportLogModal from '@/components/app/promotion/PromotionImportLogModal'
import currentUser from '@/libs/users'
import Promotions from '@/helpers/Promotions'

export default {
  name: 'ParticipantList',
  directives: {
    Ripple,
  },
  components: {
    PromotionImportLogModal,
    PrizeDrawFileImport,
    ParticipantDetailModal,
    ChartMedianNumbersByPerson,
    ChartBarParticipantPerOrigin,
    ChartBarParticipantPerState,
  },
  data() {
    return {
      search: {
        search: '',
        type: '',
      },
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-30',
          sortable: true,
        },
        {
          key: 'document',
          label: 'Cpf',
          class: 'w-10',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Data de Cadastro',
          class: 'w-10',
          sortable: true,
        },
        {
          key: 'state',
          label: 'UF',
          class: 'w-10',
          sortable: true,
        },
        {
          key: 'city',
          label: 'Cidade',
          class: 'w-10',
          sortable: true,
        },
        {
          key: 'luck_numbers',
          label: 'Números',
          class: 'w-10',
          sortable: true,
        },
        {
          key: 'ineligibility',
          label: 'Elegível',
          class: 'w-10',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-20',
        },
      ],
      items: [],
      currentClient: null,
      isShowParticipant: false,
      isShowImportLog: false,
      isShowButtonsFilesImport: false,
      currentType: '',
      currentPromotion: null,
      currentPrizeDraw: null,
      typesIndexes: [],
      isActivated: false,
      currentParticipant: '',
      isUploadActivated: false,
      isUpdateParticipants: false,
      hasBusinessInteligenceConfiguration: false,
      numbersByParticipant: [{
        icon: 'TrendingUpIcon',
        color: 'light-success',
        title: 1,
        subtitle: '',
        customClass: 'mb-2 mb-xl-0',
      }],
    }
  },
  async mounted() {
    const promotion = this.$user.getCurrentPromotion()
    if (!promotion) {
      await this.$router.push(`/change-promotions?ref=${this.$route.path}`)
      return
    }
    this.currentPromotion = promotion
    const prizeDraw = this.$user.getCurrentPrizeDraw()
    if (!prizeDraw) {
      await this.$router.push(`/change-promotions?ref=${this.$route.path}`)
      return
    }
    this.currentPrizeDraw = prizeDraw
    await this.getParticipants(1)
    await this.getManagers()
    await this.hasBusinessInteligence()
  },
  methods: {
    async getParticipants(page) {
      this.items = await this.$search.get(
        'participants',
        page,
        this.search,
        'ineligibility',
      )
    },
    isAccessToRoleAndTypeCoupon() {
      const userTypes = ['super_admin', 'admin', 'user']
      const user = currentUser.getCurrentUser()
      const promotion = this.$user.getCurrentPromotion()
      const setting = promotion.settings
      return (userTypes.includes(user.type) && setting.is_coupon_promotion)
    },
    avatarText(value) {
      if (!value) {
        return ''
      }
      const words = value.split(' ')
      const nameArray = [words[0]]
      if (words.length > 0) {
        nameArray.push(words[words.length - 1])
      }

      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
    setCurrentParticipant(participant) {
      this.currentParticipant = participant.uuid
      this.isShowParticipant = true
    },
    onCloseParticipantModal() {
      this.currentParticipant = ''
      this.isShowParticipant = false
    },
    async onExportSecap() {
      const response = await this.$http.get(`prize-draws/${this.currentPrizeDraw.uuid}/export-secap`)
      if (response.error) {
        this.$notify.error(response.error_message)
        return false
      }
      this.$notify.success(response.message)
      return true
    },
    async onExportXls() {
      const search = this.$search.parseSearchString(this.search)

      const response = await this.$http.get(`/participants/export?search=${search}&include=ineligibility`)
      if (response.error) {
        this.$notify.error(response.error_message)
        return false
      }
      this.$notify.success(response.message)
      return true
    },
    importFile() {
      this.isUploadActivated = true
      this.isUpdateParticipants = false
    },
    onSave() {
      this.isUploadActivated = false
    },
    updateParticipants() {
      this.isUploadActivated = true
      this.isUpdateParticipants = true
    },
    onCancelEdit() {
      this.isUploadActivated = false
    },
    showImportDetails() {
      this.isShowImportLog = true
    },
    onCloseLogImport() {
      this.isShowImportLog = false
    },
    async getManagers() {
      if (!this.currentPromotion.uuid) {
        return
      }
      const response = await this.$http.get(`/promotions/${this.currentPromotion.uuid}/managers`)
      if (response && response.length > 0) {
        response.forEach(user => {
          if (user.uuid === this.$user.getCurrentUser().uuid) {
            this.isShowButtonsFilesImport = true
          }
        })
      }
    },
    async hasBusinessInteligence() {
      this.hasBusinessInteligenceConfiguration = await Promotions.hasBusinessIntelligenceConfiguration(this.currentPromotion.uuid)
    },
  },
}
</script>

<style scoped>

</style>
