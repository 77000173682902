<template>
  <b-card md="12">
    <b-row>
      <b-col md="4">
        <h2>
          {{ name }}
        </h2>
      </b-col>
      <b-col
        md="8"
        class="text-right"
      >
        <slot />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: 'PageHeader',
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['name'],
  data: () => ({
  }),
  mounted() {
  },
  methods: {},
}
</script>

<style scoped>

</style>
