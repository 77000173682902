<template>
  <b-card-code>
    <page-header
      name="Business Inteligence"
    />
    <div class="content-container">
      <iframe
        :src="iframeUrl"
        width="100%"
        height="400"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import jwt from 'jsonwebtoken'

export default {
  components: {
    BCardCode,
  },
  data() {
    return {
      iframeUrl: '',
    }
  },
  mounted() {
    this.prepareParams()
  },
  methods: {
    async prepareParams() {
      const promotion = await this.$user.getCurrentPromotion()
      const biSettings = await this.$http.get(`/promotions/${promotion.uuid}/business-inteligence`)
      const METABASE_SITE_URL = process.env.VUE_APP_METABASE_URL
      const METABASE_SECRET_KEY = process.env.VUE_APP_METABASE_KEY

      console.log('aqui ', METABASE_SITE_URL, METABASE_SECRET_KEY)

      let properties = '#bordered=true&titled=true'
      if (biSettings.properties) {
        properties = biSettings.properties
      }
      let payload = {
        exp: Math.round(Date.now() / 1000) + (10 * 60), // 10 minute expiration
      }
      payload = { ...biSettings.payload }
      const token = jwt.sign(payload, METABASE_SECRET_KEY)
      this.iframeUrl = `${METABASE_SITE_URL}/embed/dashboard/${token}${properties}`
    },
  },
}
</script>
<style>
.content-container {
  height: 1200px;
  overflow: hidden;
  position: relative;
}

iframe {
  width: 100%;
  height: 1300px;
  border: none;
}
</style>
