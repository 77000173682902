<template>
  <b-card>
    <page-header
      name="Business Inteligence"
    >
      <b-button variant="primary" class="mr-1" @click="saveConfiguration">
        Salvar
      </b-button>
      <b-button variant="outline-danger" @click="destroyConfiguration">
        Excluir
      </b-button>
    </page-header>
    <b-card-body>
      <b-row>
        <b-col sm="6" md="6">
          <i2-json-editor v-model="model.payload" label="Payload" name="payload" />
        </b-col>
        <b-col sm="6" md="6">
          <i2-form-input v-model="model.properties" label="Propriedades" name="properties" />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

import I2JsonEditor from '@/components/i2/forms/elements/I2JsonEditor.vue'

export default {
  name: 'TabBusinessInteligence',
  components: {
    I2JsonEditor,
  },
  props: {
    promotion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {
        properties: '',
        payload: {},
      },
    }
  },
  watch: {
    promotion(val) {
      if (val.uuid) {
        this.getConfiguration()
      }
    },
  },
  methods: {
    async saveConfiguration() {
      if (typeof this.model.payload === 'object') {
        this.model.payload = JSON.stringify(this.model.payload)
      }
      const response = await this.$http.post(`/promotions/${this.promotion.uuid}/business-inteligence`, this.model)
      if (response.error_message) {
        this.$notify.error(response.error_message)
        return
      }
      this.$notify.success('Configuração salva com sucesso.')
    },
    async getConfiguration() {
      const response = await this.$http.get(`/promotions/${this.promotion.uuid}/business-inteligence`)
      this.model = { ...response }
    },
    async destroyConfiguration() {
      this.$bvModal
        .msgBoxConfirm('Você confirma a exclusão das configurações de BI?', {
          title: 'Confirma a exclusão?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async affirmative => {
          if (affirmative) {
            const response = await this.$http.delete(`promotions/${this.promotion.uuid}/business-inteligence`)
            if (response.error) {
              this.$notify.error(response.error_message)
              return
            }
            this.$notify.success('Configuração excluída com sucesso.')
            this.resetModel()
          }
        })
    },
    resetModel() {
      this.model = {
        properties: '',
        payload: {},
      }
    },
  },
}
</script>

<style scoped>

</style>
