// src/helpers/BusinessIntelligenceHelper.js
import axios from '@axios' // Importando diretamente o axios configurado

export default class PromotionsHelper {
  static async hasBusinessIntelligenceConfiguration(uuidPromotion) {
    try {
      const response = await axios.get(`/promotions/${uuidPromotion}/business-inteligence`)
      return !!(response && response.payload)
    } catch (error) {
      console.error('Erro ao buscar configuração de Business Intelligence:', error)
      throw error // Propaga o erro para tratamento externo
    }
  }
}
